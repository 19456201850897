import {
  type LocaleIso,
  type LocaleMorpheus,
  type PartialSolvariEnv,
  singleton,
} from "@solvari/common-fe/helpers";
import {
  getDotEnv,
  getEnv as getEnvCommon,
  initEnv,
  mergeDeep,
} from "@solvari/common-fe/helpers";

type SolvariConfigComposer = {
  auth: {
    attributionUserId: string | null;
    jwtToken: string | null;
  };
  config: {
    envBE: "acceptance" | "develop" | "local" | "production" | "testing";
    lang: "fr" | "nl";
    localeCode: LocaleMorpheus;
    localeIso: LocaleIso;
    urlPrefix: "fr" | "nl";
  };
  integrations: {
    algolia: {
      applicationId: string;
      env: "dev" | "prd" | "stg";
      publicSearchApiKey: string;
    };
    pusher: {
      appId: string;
      cluster: string;
      key: string;
    };
  };
  network: {
    admin: {
      base: string;
      baseWithPrefix: string;
    };
    api: {
      base: string;
    };
    argus: {
      base: string;
    };
    cdn: {
      morpheus: string;
    };
    client: {
      base: string;
      baseWithPrefix: string;
      "nl-NL": string;
    };
    pro: {
      base: string;
      baseWithPrefix: string;
    };
  };
};

const env = {
  config: {
    envFE: import.meta.env.MODE as "development" | "production" | "staging",
    isDev: import.meta.env.DEV,
  },
  network: {
    admin: {
      base: getDotEnv("VITE_URL_ADMIN", import.meta.env.VITE_URL_ADMIN),
    },
    api: {
      base: getDotEnv("VITE_URL_API", import.meta.env.VITE_URL_API),
    },
    argus: {
      base: getDotEnv("VITE_URL_ARGUS_API", import.meta.env.VITE_URL_ARGUS_API),
    },
    cdn: {
      morpheus: getDotEnv(
        "VITE_URL_CDN_MORPHEUS",
        import.meta.env.VITE_URL_CDN_MORPHEUS,
      ),
    },
  },
  integrations: {
    algolia: {
      applicationId: getDotEnv(
        "VITE_ALGOLIA_APPLICATION_ID",
        import.meta.env.VITE_ALGOLIA_APPLICATION_ID,
      ),
      publicSearchApiKey: getDotEnv(
        "VITE_ALGOLIA_PUBLIC_SEARCH_API_KEY",
        import.meta.env.VITE_ALGOLIA_PUBLIC_SEARCH_API_KEY,
      ),
      env: getDotEnv<"dev" | "prd" | "stg">(
        "VITE_ALGOLIA_ENV",
        import.meta.env.VITE_ALGOLIA_ENV,
      ),
    },
    pusher: {
      appId: getDotEnv(
        "VITE_PUSHER_APP_ID",
        import.meta.env.VITE_PUSHER_APP_ID,
      ),
      key: getDotEnv(
        "VITE_PUSHER_APP_KEY",
        import.meta.env.VITE_PUSHER_APP_KEY,
      ),
      cluster: getDotEnv(
        "PUSHER_APP_CLUSTER",
        import.meta.env.VITE_PUSHER_APP_CLUSTER,
      ),
    },
  },
} satisfies PartialSolvariEnv;

const initSolvariConfig = singleton(() => {
  const mountElement = document.querySelector<HTMLElement>("#solvari-config");

  if (!mountElement) {
    throw new Error("#solvari-config element is missing");
  }

  const overrides = JSON.parse(
    mountElement.dataset.props!,
  ) as SolvariConfigComposer;

  const solvariConfig = mergeDeep(env, overrides);

  initEnv(solvariConfig);

  window.solvariEnv = solvariConfig;
});

initSolvariConfig();

const getEnv = getEnvCommon<SolvariConfigComposer & typeof env>;

export { getEnv };
